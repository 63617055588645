// eslint-disable-next-line import/no-unresolved
import 'vite/modulepreload-polyfill';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import LazyBootstrap from './LazyBootstrap';
import Loader from './components/common/Loader';
import './index.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const element = (
    <Suspense fallback={<Loader />}>
        <LazyBootstrap />
    </Suspense>
);

root.render(element);
